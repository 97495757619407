import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import babyYodaImage from '../images/babyyoda.jpg'

import Img from "gatsby-image"
//TODO MIGUEL: Find out how to use tests: "test": "echo \"Write tests! -> https://gatsby.dev/unit-testing\" && exit 1"
const IndexPage = () => {
  const data1 = useStaticQuery(graphql`
  query {
    placeholderImage: file(relativePath: { eq: "images/babyyoda.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <h1>Miguel Was Here</h1>
      <p>This is just a testing page I use for random experiments.</p>
      <p>This site has CI/CD!</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
        <img src={babyYodaImage} alt="Baby Yoda"></img>
        <Img fluid={data1.placeholderImage.childImageSharp.fluid} alt="Another Baby Yoda Image"></Img>
      </div>
      <Link to="/page-2/">Go to page 2</Link>
      <br />
      <Link to="/counter/">Go to counter-react</Link>
      <br />
      <Link to="/counter-gts/">Go to counter-gatsby</Link>
      <br />
      <Link to="/files-test/">Go to files-test</Link>
      <br />
      <Link to="/posts/">Go to posts</Link>
      <br />
      <Link to="/contact/">Go to contact</Link>
      <br />
      <Link to="/modal/">Go to modal</Link>
      <br />
      <Link to="/pokemon/">Go to pokemon</Link>
    </Layout>
  )
}

export default IndexPage
